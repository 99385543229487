import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import * as ffsStyles from "../components/sectionWrap/sectionWrap.module.css"
import SectionWrap from "../components/sectionWrap"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import "../components/layout/layout.css"
import Introtext from "../components/introtext"
import { Helmet } from "react-helmet"
import { SwiperSlide } from "swiper/react"
import SwiperDefault from "../components/swiperDefault"
import { Col, Container, Row } from "react-bootstrap"
import WhatsNext from "../components/whatsNext/whatsnext"
import ListenModule from "../components/listenModule"
import Ausbildungheader from "../components/header/ausbildung/index"
import { Link } from "gatsby"
import Kontakt from "../components/kontakt"

const Ausbildung = () => {
  const data = useStaticQuery(graphql`
    query AusbildungQuery {
      ogimage: file(relativePath: { eq: "images/ausbildung-og.jpg" }) {
        childImageSharp {
          fixed(height: 400) {
            src
            height
            width
          }
        }
      }
      heropic: file(relativePath: { eq: "images/jobs/header-jobs.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            aspectRatio: 1.7
          )
        }
      }
      fi01: file(relativePath: { eq: "images/ausbildung/fachinformatiker.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: WEST, grayscale: false }
            aspectRatio: 1
          )
        }
      }
      fi02: file(relativePath: { eq: "images/ausbildung/fi02.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: true }
            aspectRatio: 1
          )
        }
      }
      fi03: file(relativePath: { eq: "images/ausbildung/fi03.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: true }
            aspectRatio: 1
          )
        }
      }
      fi04: file(relativePath: { eq: "images/ausbildung/IMG_3767-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: true }
            aspectRatio: 1
          )
        }
      }      
     
      mg01: file(relativePath: { eq: "images/ausbildung/mediengestalter.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: WEST, grayscale: false }
            aspectRatio: 1
          )
        }
      }
      mg02: file(relativePath: { eq: "images/ausbildung/mg02.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: false }
            aspectRatio: 1
          )
        }
      }
      mg03: file(relativePath: { eq: "images/ausbildung/mg03.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: false }
            aspectRatio: 1
          )
        }
      }    
      mg04: file(relativePath: { eq: "images/ausbildung/mg04.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: false }
            aspectRatio: 1
          )
        }
      }                      
      mg05: file(relativePath: { eq: "images/ausbildung/mg05.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: false }
            aspectRatio: 1
          )
        }
      }    
      mg06: file(relativePath: { eq: "images/ausbildung/mg06.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: false }
            aspectRatio: 1
          )
        }
      }      
      mg07: file(relativePath: { eq: "images/ausbildung/IMG_3761.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: false }
            aspectRatio: 1
          )
        }
      }     
      mg08: file(relativePath: { eq: "images/ausbildung/IMG_3763.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: false }
            aspectRatio: 1
          )
        }
      }  
      mg09: file(relativePath: { eq: "images/ausbildung/IMG_3768.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: false }
            aspectRatio: 1
          )
        }
      }                  
      im01: file(relativePath: { eq: "images/ausbildung/im1-CW006257.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: WEST, grayscale: false }
            
          )
        }
      } 
      im02: file(relativePath: { eq: "images/ausbildung/im2-CW006134.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: false }
            
          )
        }
      } 
      im03: file(relativePath: { eq: "images/ausbildung/im03-DSC_3025.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: false }
            
          )
        }
      }   
      im04: file(relativePath: { eq: "images/ausbildung/im04-CW006270.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: false }
            
          )
        }
      } 
      im05: file(relativePath: { eq: "images/ausbildung/im05-CW009816.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: false }
            
          )
        }
      } 
      im06: file(relativePath: { eq: "images/ausbildung/im06-CW006162.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: false }
            
          )
        }
      }  
      im07: file(relativePath: { eq: "images/ausbildung/im07-CW006283.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: false }
            
          )
        }
      }  
      im08: file(relativePath: { eq: "images/ausbildung/im08-CW006231.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: false }
            
          )
        }
      }  
      im09: file(relativePath: { eq: "images/ausbildung/im09-CW006173.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: false }
            
          )
        }
      }     
      im10: file(relativePath: { eq: "images/ausbildung/IMG_3763.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: false }
            
          )
        }
      }                                                                
    }
  `)

  const kununu = (
    <a
      href="https://www.kununu.com/de/qmarketing1?utm_source=widget&utm_campaign=widget_selfservice_scoreandreview"
      target="_blank"
      rel="nofollow"
    >
      <img
        src="https://www.kununu.com/de/partner/KlBaCFdf/self-service-button?button-type=2"
        width="170"
        height="100"
        className="mt-4 w-50 mx-auto ml-md-0 d-block"
        alt="kununu"
      />
    </a>
  )

  return (
    <Layout>
      <SEO
        title="Ausbildung bei Q:marketing"
        description=""
        image={data?.ogimage?.childImageSharp?.fixed}
      />

      {/* Header */}
      <Ausbildungheader />

      {/* Ueber Q */}
      {/* <SectionWrap className={`container-fluid height50`} pin={false} snap={false} bgCol={"#fff"}>
        <div className={`row ${ffsStyles.vcenterRowContent}`}>
          <div className="offset-md-3 col-12 col-md-6 text-center">
            <h3>Willkommen bei Q:MARKETING</h3>
            <p class="introtext">Q:MARKETING ist eine inhabergeführte <Link to="/agentur">Marketingagentur</Link> mit internationalen sowie regionalen Kunden, einem breiten Kompetenzspektrum und rund 35 Mitarbeitern. Unser Agentursitz ist seit über 20 Jahren in einer alten Lederfabrik in Mülheim-Saarn.</p>
          </div>
        </div>
      </SectionWrap> */}

      {/* fullwidth swiper mit spalten pro slide */}
      <div id="fachinformatiker" className="container-fluid bg-q-black">
        <SectionWrap pin={false} snap={true}>
          <div className={`row ${ffsStyles.vcenterRowContent}`}>
            <div className={`col-12 col-md-6 mx-0 px-0`}>
              <SwiperDefault>
                <SwiperSlide>
                  <GatsbyImage
                    image={data.fi01.childImageSharp.gatsbyImageData}
                    alt=""
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <GatsbyImage
                    image={data.fi02.childImageSharp.gatsbyImageData}
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <GatsbyImage
                    image={data.fi04.childImageSharp.gatsbyImageData}
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>                   
                <SwiperSlide>
                  <GatsbyImage
                    image={data.fi03.childImageSharp.gatsbyImageData}
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>             
              </SwiperDefault>
            </div>

            <div className={`col-12 col-md-6 mx-0 px-0 py-5 py-md-0`}>
              <Introtext
                skyhead={
                  <span style={{ color: "#ff6600" }}>
                    Ausbildung zum
                  </span>
                }
                titel={
                  <span style={{ color: "#ff6600" }}>
                    Fachinformatiker für Anwendungsentwicklung (m/w/d)
                  </span>
                }
                text={
                  <div className="text-white weisseLinks hoverback-black">
                    <p>
                      Als Fachinformatiker:in arbeitest du bei uns in kleinen Teams an der Entwicklung von digitalen Anwendungen wie <Link to="/projekte/sales-apps">Webanwendungen</Link>, <Link to="/projekte/konfiguratoren">Konfiguratoren</Link> oder <Link to="/projekte/websites">Websites</Link>. Du programmierst, lernst das Design von Softwarearchitekturen und entwirfst Datenbanken. Du wirst mit verschiedenen Entwicklungstools und -umgebungen vertraut gemacht und wirst fit im Projektmanagement. Qualitätssicherung und Dokumentation gehören auch zum Ausbildungsinhalt. Nach Abschluss der Ausbildung kannst du als Softwareentwickler:in, Programmierer:in oder Anwendungsentwickler:in durchstarten.
                    </p>
                  </div>
                }
                showCta={true}
                link={"https://join.com/companies/qmarketing/9390409-ausbildung-zum-fachinformatiker-anwendungsentwicklung-w-m-d"}
                linklabel={"Jetzt Bewerben"}
                fitContent={true}
              />
            </div>
          </div>
        </SectionWrap>
      </div>

      {/* fullwidth swiper mit spalten pro slide*/}
      <div id="mediengestalter" className="container-fluid my-0">
        <SectionWrap snap={true} pin={false}>
          <div className={`row ${ffsStyles.vcenterRowContent} bg-q-orange`}>
            <div className={`col-12 col-md-6 mx-0 px-0 py-5 py-md-0`}>
              <Introtext
                skyhead={
                  <span style={{ color: "#000" }}>
                    Ausbildung zum
                  </span>
                }
                titel={
                  <span style={{ color: "#000" }}>
                    Mediengestalter (m/w/d)
                  </span>
                }
                text={
                  <div className="text-white weisseLinks hoverback-orange">
                    <p>
                      Die Ausbildung zum Mediengestalter ist eine kreative und technisch orientierte Ausbildung. Du lernst, ansprechende Grafiken, Layouts und Designs für verschiedene Medienprodukte zu erstellen. Dies kann <Link to="/qmarketing-fuer-den-mittelstand/kundenmagazin-journal">Printmedien</Link>, <Link to="/projekte/websites">Webseiten</Link>, <Link to="/qmarketing-fuer-den-mittelstand/app">Apps</Link>, <Link to="/qmarketing-fuer-den-mittelstand/animation-und-film">Videos</Link>, und andere digitale Plattformen umfassen. Nach Abschluss der Ausbildung kannst du als Grafikdesigner, Webdesigner, Multimedia-Produzent oder in anderen kreativen Positionen tätig sein.
                    </p>
                  </div>
                }
                showCta={false}
                showCtaSecondaryWhite={true}
                link={"https://join.com/companies/qmarketing/5379146-ausbildung-zum-mediengestalter-w-m-d"}
                linklabel={"Jetzt Bewerben"}
                fitContent={true}
              />
            </div>

            <div
              className={`col-12 col-md-6 mx-0 px-0 order-first order-md-last`}
            >
              <SwiperDefault>
                <SwiperSlide>
                  <GatsbyImage
                    image={data.mg01.childImageSharp.gatsbyImageData}
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <GatsbyImage
                    image={data.mg06.childImageSharp.gatsbyImageData}
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <GatsbyImage
                    image={data.mg02.childImageSharp.gatsbyImageData}
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <GatsbyImage
                    image={data.mg07.childImageSharp.gatsbyImageData}
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>                
                <SwiperSlide>
                  <GatsbyImage
                    image={data.mg05.childImageSharp.gatsbyImageData}
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <GatsbyImage
                    image={data.mg08.childImageSharp.gatsbyImageData}
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <GatsbyImage
                    image={data.mg03.childImageSharp.gatsbyImageData}
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>                
                <SwiperSlide>
                  <GatsbyImage
                    image={data.mg09.childImageSharp.gatsbyImageData}
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>                                
              </SwiperDefault>
            </div>
          </div>
        </SectionWrap>
      </div>


      {/* was deine ausbildung bei Q ausmacht d-flex align-items-center justify-content-center*/}
      <SectionWrap className={`p-5 m-5 standard`} pin={false} snap={false} bgCol={"#ffffff"}>
        <div className={`row`}>
          <div className="col-12 text-center mb-4">
            <h3>Was deine Ausbildung bei uns ausmacht</h3>
          </div>
        </div>
        <div className={`row`}>
          <div className="col-12 offset-md-1 col-md-5 qliste liniert-orange">
            <ul className="text-center">
              <li><strong>Kreatives Umfeld</strong></li>
              <li><strong>Nette Kollegen</strong></li>
              <li><strong>Spannende Projekte</strong></li>
            </ul>
          </div>
          <div className="col-12 col-md-5 qliste liniert-orange">
            <ul className="text-center">
              <li><strong>Namhafte Kunden</strong></li>
              <li><strong>Vertrauensvolle Zusammenarbeit</strong></li>
              <li><strong>Option auf Übernahme</strong></li>
            </ul>
          </div>
        </div>
      </SectionWrap>

      {/* besuche uns 
      <SectionWrap className={`p-5 m-5 standard`} pin={false} snap={false} bgCol={"#ff6600"}>
        <div className={`row`}>
          <div className="col-12 text-center">
            <h3 className="text-white">Besuche uns auf der <a className="weisseLinks" href="https://www.muelheim-ruhr.de/cms/ausbildungsmesse_berufsstart3.html" target="_blank">#ausbildung2024</a> am 22. November in der Stadthalle Mülheim!</h3>
          </div>
        </div>
      </SectionWrap>
      */}


      {/* impressionen swiper voll breite */}
      <SectionWrap bgCol={"#ffffff"} pin={false} snap={true}>
        <div className={`row ${ffsStyles.vcenterRowContent}`}>
          <div className={`col-12`}>
            <SwiperDefault navi={true} effect={"coverflow"}>
              <SwiperSlide>
                <GatsbyImage
                  image={data.im01.childImageSharp.gatsbyImageData}
                  placeholder="blurred"
                  className="fullHeight"
                />
              </SwiperSlide>
              <SwiperSlide>
                <GatsbyImage
                  image={data.im02.childImageSharp.gatsbyImageData}
                  placeholder="blurred"
                  className="fullHeight"
                />
              </SwiperSlide>
              <SwiperSlide>
                <GatsbyImage
                  image={data.im03.childImageSharp.gatsbyImageData}
                  placeholder="blurred"
                  className="fullHeight"
                />
              </SwiperSlide>
              <SwiperSlide>
                <GatsbyImage
                  image={data.im04.childImageSharp.gatsbyImageData}
                  placeholder="blurred"
                  className="fullHeight"
                />
              </SwiperSlide>

              <SwiperSlide>
                <GatsbyImage
                  image={data.im05.childImageSharp.gatsbyImageData}
                  placeholder="blurred"
                  className="fullHeight"
                />
              </SwiperSlide>

              <SwiperSlide>
                <GatsbyImage
                  image={data.im10.childImageSharp.gatsbyImageData}
                  placeholder="blurred"
                  className="fullHeight"
                />
              </SwiperSlide>              
              
              <SwiperSlide>
                <GatsbyImage
                  image={data.im06.childImageSharp.gatsbyImageData}
                  placeholder="blurred"
                  className="fullHeight"
                />
              </SwiperSlide>
              <SwiperSlide>
                <GatsbyImage
                  image={data.im07.childImageSharp.gatsbyImageData}
                  placeholder="blurred"
                  className="fullHeight"
                />
              </SwiperSlide>
              <SwiperSlide>
                <GatsbyImage
                  image={data.im08.childImageSharp.gatsbyImageData}
                  placeholder="blurred"
                  className="fullHeight"
                />
              </SwiperSlide>
              <SwiperSlide>
                <GatsbyImage
                  image={data.im09.childImageSharp.gatsbyImageData}
                  placeholder="blurred"
                  className="fullHeight"
                />
              </SwiperSlide>

            </SwiperDefault>
          </div>
        </div>
      </SectionWrap>


      {/* qmpels mit ausbildung bei q */}




      {/* kontakt */}
      {/* <Kontakt ansprechpartner="christian-frieling" /> */}

      {/* 
        next
      */}

      <WhatsNext
        text="Hier erfährst du mehr über die Agentur &rarr;"
        link="/agentur"
      />
    </Layout>
  )
}

export default Ausbildung
