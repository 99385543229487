import React, { useEffect } from "react"
import * as HeroStyles from "./ausbildungheader.module.css"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Link } from "gatsby"
import {AnchorLink} from "gatsby-plugin-anchor-links"

const Ausbildungheader = () => {

  useEffect(() => {

    //einrasten der herosections fuer desktop und tablet

    gsap.registerPlugin(ScrollTrigger)

    //setter
    gsap.set(["#bubble-orange","#speech", ".wort"], { autoAlpha: 0, transformOrigin: "center" }); 
  

    var tl = gsap.timeline({
      // scrollTrigger: {
      //   trigger: ".headerWrap",
      //   pin: true,
      //   scrub: 1,
      //   start: "top top",
      //   end: "bottom center",
      //   markers: false,
      //   pinSpacing: true,
      // }
    })      
      .addLabel("eins")                  
      .fromTo("#bubble-orange", {yPercent:"+=15", scale:".8"}, { autoAlpha: 1, yPercent: "0", scale:"1", duration: 2, ease: "elastic.out(.5, .3)", delay: .25})
      .fromTo("#speech", {yPercent:"+=15", scale:".8", rotation:"15"}, { autoAlpha: 1, yPercent: "0", scale:"1", rotation:"0", duration: 3, ease: "elastic.out(1, 0.4)"}, "<")
      .fromTo(".wort", {yPercent:"+=25", scale:"1.5", rotation:"-5"}, { autoAlpha: 1, stagger: {amount: 1, from: "start"}, overwrite: true, yPercent:"0", scale:"1", rotation:"0", duration: "0.75" }, "<")
      .addLabel("ende")

  }, [])

  return (

    <div className="headerWrap">
      <div>
        <svg className="fullorhalfpage" viewBox="0 0 1920 1080" preserveAspectRatio="xMaxYMid slice" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="all">
            <rect id="Rectangle 5" width="1920" height="1080" fill="#F1F3F4" />
            <g id="bubble-all">
              <path id="bubble-orange" d="M1556.67 -33.0025C1361.21 -59.1896 986.077 94.1466 934.492 166.713C882.906 239.122 718.685 370.373 774.372 572.297C830.059 774.221 968.566 603.69 1150.93 956.584C1333.29 1309.48 1602.1 1045.24 1672.15 1008.48C1742.35 971.728 2263.88 709.858 2053.59 331.408C1843.62 -46.7271 1596.58 -27.6389 1556.67 -33.0025Z" fill="#FF6600" />
              <g id="text-all">
                <g id="einegute">
                  <g id="eine" class="wort">
                    <path id="Vector" d="M1072.37 321.627V235.493H1130.89V252.215H1091.14V269.726H1125.37V286.448H1091.14V305.063H1130.89V321.784H1072.37V321.627Z" fill="white" />
                    <path id="Vector_2" d="M1143.83 321.627V306.64H1154.87V250.48H1143.83V235.493H1184.69V250.48H1173.65V306.64H1184.69V321.627H1143.83Z" fill="white" />
                    <path id="Vector_3" d="M1226.65 280.926L1218.45 263.731H1218.13V321.627H1200.46V235.493H1220.97L1244.48 276.194L1252.68 293.389H1253V235.493H1270.66V321.627H1250.16L1226.65 280.926Z" fill="white" />
                    <path id="Vector_4" d="M1289.75 321.627V235.493H1348.28V252.215H1308.53V269.726H1342.76V286.448H1308.53V305.063H1348.28V321.784H1289.75V321.627Z" fill="white" />
                  </g>
                  <g id="gute" class="wort">
                    <path id="Vector_5" d="M1448.29 307.902H1447.66C1447.03 312.319 1444.82 315.947 1441.04 318.787C1437.25 321.627 1432.05 323.046 1425.58 323.046C1420.69 323.046 1416.11 322.1 1411.7 320.207C1407.44 318.314 1403.65 315.474 1400.5 311.846C1397.34 308.218 1394.82 303.485 1392.92 297.964C1391.19 292.442 1390.24 286.132 1390.24 278.875C1390.24 271.619 1391.19 265.309 1393.08 259.63C1394.97 254.108 1397.66 249.376 1400.97 245.59C1404.44 241.803 1408.54 238.964 1413.27 236.913C1418.01 235.02 1423.37 233.916 1429.21 233.916C1436.94 233.916 1443.56 235.651 1449.24 238.964C1454.76 242.277 1459.18 247.325 1462.49 253.95L1447.19 262.627C1445.93 259.314 1443.88 256.474 1441.04 254.108C1438.2 251.742 1434.41 250.638 1429.36 250.638C1423.53 250.638 1418.95 252.373 1415.48 255.686C1412.01 258.999 1410.28 264.362 1410.28 271.619V285.501C1410.28 292.6 1412.01 297.964 1415.48 301.276C1418.95 304.747 1423.53 306.325 1429.36 306.325C1431.73 306.325 1433.78 306.009 1435.83 305.536C1437.88 305.062 1439.62 304.116 1441.2 303.012C1442.77 301.907 1444.04 300.645 1444.82 299.068C1445.61 297.49 1446.09 295.597 1446.09 293.546V289.287H1430V273.67H1463.91V321.627H1448.14V307.902H1448.29Z" fill="white" />
                    <path id="Vector_6" d="M1500.04 235.493V288.341C1500.04 294.493 1501.3 298.91 1503.67 301.907C1506.03 304.905 1509.98 306.482 1515.66 306.482C1521.18 306.482 1525.28 304.905 1527.64 301.907C1530.01 298.91 1531.27 294.335 1531.27 288.341V235.493H1549.57V286.29C1549.57 292.6 1548.94 298.121 1547.84 302.696C1546.57 307.271 1544.68 311.215 1542 314.212C1539.32 317.21 1535.85 319.418 1531.59 320.838C1527.33 322.258 1522.12 323.046 1515.97 323.046C1509.82 323.046 1504.61 322.258 1500.35 320.838C1496.09 319.418 1492.62 317.21 1489.94 314.212C1487.26 311.215 1485.21 307.429 1484.1 302.696C1482.84 298.121 1482.37 292.6 1482.37 286.29V235.493H1500.04Z" fill="white" />
                    <path id="Vector_7" d="M1603.68 252.215V321.627H1584.91V252.215H1561.56V235.493H1626.87V252.215H1603.68Z" fill="white" />
                    <path id="Vector_8" d="M1639.81 321.627V235.493H1698.49V252.215H1658.74V269.726H1692.97V286.448H1658.74V305.063H1698.49V321.784H1639.81V321.627Z" fill="white" />
                  </g>
                </g>
                <g id="ausbildung" class="wort">
                  <path id="Vector_9" d="M1113.23 433L1108.34 416.436H1085.15L1080.26 433H1064.95L1087.83 363.589H1106.44L1129 433H1113.23ZM1096.82 377.314H1096.35L1088.62 403.658H1104.55L1096.82 377.314Z" fill="white" />
                  <path id="Vector_10" d="M1150.61 363.589V406.182C1150.61 411.073 1151.56 414.701 1153.45 417.225C1155.35 419.591 1158.66 420.853 1163.08 420.853C1167.65 420.853 1170.81 419.591 1172.7 417.225C1174.59 414.859 1175.54 411.073 1175.54 406.182V363.589H1190.21V404.605C1190.21 409.653 1189.74 414.07 1188.79 417.856C1187.84 421.642 1186.27 424.64 1184.06 427.164C1181.85 429.53 1179.01 431.423 1175.54 432.527C1172.07 433.631 1167.81 434.262 1162.92 434.262C1158.03 434.262 1153.77 433.631 1150.3 432.527C1146.83 431.423 1143.99 429.53 1141.78 427.164C1139.57 424.797 1137.99 421.642 1137.05 417.856C1136.1 414.07 1135.63 409.653 1135.63 404.605V363.589H1150.61Z" fill="white" />
                  <path id="Vector_11" d="M1227.6 434.105C1221.45 434.105 1216.4 433 1212.14 430.95C1207.88 428.899 1204.25 426.217 1201.25 422.904L1211.19 412.808C1215.92 418.014 1221.76 420.696 1228.54 420.696C1232.33 420.696 1235.01 419.907 1236.75 418.487C1238.48 416.91 1239.43 415.017 1239.43 412.493C1239.43 410.599 1238.96 409.022 1237.85 407.76C1236.75 406.498 1234.7 405.551 1231.38 405.078L1224.6 404.132C1217.19 403.185 1211.82 400.977 1208.51 397.506C1205.04 394.035 1203.46 389.303 1203.46 383.466C1203.46 380.311 1204.09 377.471 1205.2 374.947C1206.46 372.423 1208.04 370.215 1210.24 368.322C1212.45 366.429 1215.14 365.009 1218.45 364.062C1221.6 362.958 1225.39 362.485 1229.49 362.485C1234.7 362.485 1239.27 363.273 1243.37 365.009C1247.32 366.744 1250.79 369.11 1253.63 372.265L1243.53 382.362C1241.95 380.469 1239.9 378.891 1237.54 377.629C1235.17 376.367 1232.17 375.736 1228.7 375.736C1225.39 375.736 1222.86 376.367 1221.13 377.471C1219.55 378.576 1218.61 380.311 1218.61 382.362C1218.61 384.728 1219.24 386.463 1220.5 387.41C1221.76 388.356 1223.97 389.145 1226.81 389.618L1233.59 390.723C1240.69 391.827 1246.05 394.035 1249.53 397.348C1253 400.661 1254.73 405.236 1254.73 411.388C1254.73 414.701 1254.1 417.698 1253 420.538C1251.89 423.377 1250 425.744 1247.79 427.637C1245.58 429.688 1242.74 431.107 1239.27 432.212C1235.8 433.631 1232.01 434.105 1227.6 434.105Z" fill="white" />
                  <path id="Vector_12" d="M1267.19 363.589H1300.95C1306.79 363.589 1311.21 365.167 1314.36 368.479C1317.52 371.792 1319.25 376.052 1319.25 381.415C1319.25 384.097 1318.94 386.305 1318.15 388.198C1317.52 390.092 1316.57 391.511 1315.31 392.773C1314.2 394.035 1312.78 394.824 1311.21 395.455C1309.63 396.086 1308.05 396.402 1306.16 396.402V397.033C1307.74 397.033 1309.63 397.348 1311.36 397.979C1313.1 398.61 1314.84 399.557 1316.41 400.819C1317.99 402.081 1319.25 403.816 1320.2 405.867C1321.3 407.918 1321.78 410.442 1321.78 413.439C1321.78 416.121 1321.3 418.645 1320.51 421.169C1319.57 423.535 1318.31 425.744 1316.73 427.479C1315.15 429.214 1313.26 430.634 1311.05 431.738C1308.84 432.843 1306.47 433.316 1303.95 433.316H1267.19V363.589ZM1282.34 391.985H1297.32C1299.38 391.985 1300.8 391.354 1302.06 390.249C1303.16 389.145 1303.79 387.568 1303.79 385.517V382.519C1303.79 380.469 1303.16 379.049 1302.06 377.787C1300.95 376.682 1299.38 376.052 1297.32 376.052H1282.34V391.985ZM1282.34 420.222H1299.69C1301.74 420.222 1303.32 419.749 1304.42 418.645C1305.53 417.541 1306.16 415.963 1306.16 414.07V410.757C1306.16 408.706 1305.53 407.287 1304.42 406.182C1303.32 405.078 1301.74 404.605 1299.69 404.605H1282.34V420.222Z" fill="white" />
                  <path id="Vector_13" d="M1330.77 433.001V421.011H1339.76V375.736H1330.77V363.747H1363.74V375.736H1354.75V421.011H1363.74V433.001H1330.77Z" fill="white" />
                  <path id="Vector_14" d="M1376.36 433V363.589H1391.5V419.591H1418.01V433H1376.36Z" fill="white" />
                  <path id="Vector_15" d="M1429.05 363.589H1455.24C1459.81 363.589 1463.91 364.378 1467.7 365.798C1471.48 367.217 1474.64 369.426 1477.32 372.265C1480 375.105 1482.05 378.733 1483.47 383.15C1484.89 387.568 1485.68 392.616 1485.68 398.295C1485.68 404.132 1484.89 409.18 1483.47 413.439C1482.05 417.856 1480 421.327 1477.32 424.324C1474.64 427.164 1471.48 429.372 1467.7 430.792C1463.91 432.212 1459.81 433 1455.24 433H1429.05V363.589ZM1455.24 419.591C1459.81 419.591 1463.28 418.329 1465.8 415.805C1468.33 413.281 1469.75 409.18 1469.75 403.658V392.931C1469.75 387.41 1468.49 383.308 1465.8 380.784C1463.28 378.26 1459.65 376.998 1455.24 376.998H1444.19V419.591H1455.24Z" fill="white" />
                  <path id="Vector_16" d="M1512.66 363.589V406.182C1512.66 411.073 1513.6 414.701 1515.5 417.225C1517.39 419.749 1520.7 420.853 1525.12 420.853C1529.54 420.853 1532.85 419.591 1534.74 417.225C1536.64 414.859 1537.58 411.073 1537.58 406.182V363.589H1552.25V404.605C1552.25 409.653 1551.78 414.07 1550.83 417.856C1549.89 421.642 1548.31 424.64 1546.1 427.164C1543.89 429.53 1541.05 431.423 1537.58 432.527C1534.11 433.631 1530.01 434.262 1524.96 434.262C1520.07 434.262 1515.81 433.631 1512.34 432.527C1508.87 431.423 1506.03 429.53 1503.82 427.164C1501.61 424.797 1500.04 421.642 1499.09 417.856C1498.14 414.07 1497.67 409.653 1497.67 404.605V363.589H1512.66Z" fill="white" />
                  <path id="Vector_17" d="M1588.38 400.188L1581.75 386.305H1581.44V433H1567.24V363.589H1583.8L1602.73 396.402L1609.36 410.284H1609.68V363.589H1623.87V433H1607.31L1588.38 400.188Z" fill="white" />
                  <path id="Vector_18" d="M1683.19 421.958H1682.72C1682.24 425.428 1680.35 428.426 1677.35 430.634C1674.36 433.001 1670.1 434.105 1664.89 434.105C1660.95 434.105 1657.16 433.316 1653.69 431.896C1650.22 430.319 1647.22 428.11 1644.7 425.113C1642.17 422.116 1640.12 418.487 1638.7 413.912C1637.28 409.495 1636.49 404.289 1636.49 398.453C1636.49 392.616 1637.28 387.41 1638.7 382.993C1640.28 378.418 1642.33 374.79 1645.17 371.635C1647.85 368.637 1651.17 366.271 1655.11 364.693C1658.9 363.116 1663.15 362.327 1667.89 362.327C1674.04 362.327 1679.4 363.747 1683.98 366.429C1688.55 369.11 1692.02 373.212 1694.55 378.576L1682.24 385.675C1681.14 382.993 1679.56 380.626 1677.35 378.733C1675.14 376.84 1671.99 375.894 1667.89 375.894C1663.15 375.894 1659.37 377.314 1656.69 379.995C1654 382.677 1652.59 386.937 1652.59 392.773V403.974C1652.59 409.811 1654 414.07 1656.69 416.752C1659.53 419.434 1663.15 420.854 1667.89 420.854C1669.78 420.854 1671.52 420.696 1673.09 420.223C1674.67 419.749 1676.25 419.118 1677.51 418.172C1678.77 417.225 1679.72 416.279 1680.51 415.017C1681.14 413.755 1681.61 412.335 1681.61 410.6V407.129H1668.68V394.509H1695.97V433.158H1683.19V421.958Z" fill="white" />
                </g>
                <g id="istdas">
                  <g id="ist" class="wort">
                    <path id="Vector_19" d="M1071.26 592.647V572.297H1086.41V495.313H1071.26V474.963H1127.27V495.313H1112.12V572.297H1127.27V592.647H1071.26Z" fill="white" />
                    <path id="Vector_20" d="M1186.9 594.54C1176.49 594.54 1167.81 592.805 1160.55 589.176C1153.3 585.706 1147.14 580.973 1142.1 575.452L1158.98 558.414C1167.02 567.406 1176.8 571.823 1188.47 571.823C1194.79 571.823 1199.52 570.561 1202.51 568.037C1205.51 565.513 1207.09 562.043 1207.09 557.783C1207.09 554.628 1206.14 551.789 1204.41 549.738C1202.67 547.529 1198.89 546.11 1193.37 545.321L1181.69 543.743C1169.23 542.166 1160.08 538.38 1154.24 532.385C1148.41 526.391 1145.57 518.503 1145.57 508.564C1145.57 503.201 1146.51 498.468 1148.56 494.051C1150.61 489.634 1153.45 485.848 1157.24 482.693C1161.03 479.538 1165.6 477.014 1171.12 475.279C1176.64 473.543 1182.8 472.597 1189.74 472.597C1198.57 472.597 1206.46 474.016 1213.24 476.856C1220.03 479.696 1225.86 483.797 1230.6 489.161L1213.56 506.356C1210.72 503.201 1207.41 500.519 1203.3 498.468C1199.36 496.417 1194.31 495.313 1188.16 495.313C1182.48 495.313 1178.06 496.26 1175.38 498.31C1172.54 500.361 1171.12 503.043 1171.12 506.671C1171.12 510.615 1172.23 513.613 1174.43 515.348C1176.64 517.083 1180.27 518.345 1185.16 519.134L1196.84 521.027C1208.98 522.92 1217.97 526.706 1223.81 532.227C1229.65 537.749 1232.65 545.794 1232.65 555.89C1232.65 561.57 1231.7 566.775 1229.65 571.508C1227.6 576.241 1224.6 580.342 1220.81 583.655C1216.87 586.968 1212.14 589.65 1206.46 591.543C1200.78 593.593 1194.15 594.54 1186.9 594.54Z" fill="white" />
                    <path id="Vector_21" d="M1299.53 497.522V592.489H1273.82V497.522H1241.95V474.805H1331.4V497.522H1299.53Z" fill="white" />
                  </g>
                  <g id="das" class="wort">
                    <path id="Vector_22" d="M1388.82 474.805H1433.15C1440.88 474.805 1447.98 476.067 1454.45 478.591C1460.91 481.115 1466.28 484.744 1470.85 489.634C1475.43 494.524 1478.9 500.677 1481.27 508.091C1483.79 515.506 1485.05 524.024 1485.05 533.805C1485.05 543.586 1483.79 552.104 1481.27 559.519C1478.74 566.933 1475.27 573.086 1470.85 577.976C1466.28 582.866 1460.91 586.495 1454.45 589.019C1447.98 591.543 1440.88 592.805 1433.15 592.805H1388.82V474.805ZM1433.15 569.773C1440.72 569.773 1446.87 567.564 1451.13 563.305C1455.55 559.046 1457.76 552.104 1457.76 542.639V524.34C1457.76 514.875 1455.55 508.091 1451.13 503.674C1446.72 499.415 1440.72 497.206 1433.15 497.206H1414.38V569.773H1433.15Z" fill="white" />
                    <path id="Vector_23" d="M1572.92 592.647L1564.56 564.567H1525.28L1516.92 592.647H1490.89L1529.7 474.805H1561.25L1599.58 592.647H1572.92ZM1545 497.995H1544.21L1531.27 542.797H1558.25L1545 497.995Z" fill="white" />
                    <path id="Vector_24" d="M1654.16 594.54C1643.75 594.54 1635.07 592.805 1627.82 589.176C1620.56 585.706 1614.41 580.973 1609.36 575.452L1626.24 558.414C1634.29 567.406 1644.07 571.823 1655.74 571.823C1662.05 571.823 1666.78 570.561 1669.78 568.037C1672.78 565.513 1674.35 562.043 1674.35 557.783C1674.35 554.628 1673.41 551.789 1671.67 549.738C1669.94 547.529 1666.15 546.11 1660.63 545.321L1648.96 543.743C1636.49 542.166 1627.34 538.38 1621.51 532.385C1615.67 526.391 1612.83 518.503 1612.83 508.564C1612.83 503.201 1613.78 498.468 1615.83 494.051C1617.88 489.634 1620.72 485.848 1624.5 482.693C1628.29 479.538 1632.87 477.014 1638.39 475.279C1643.91 473.543 1650.06 472.597 1657 472.597C1665.84 472.597 1673.72 474.016 1680.51 476.856C1687.29 479.696 1693.13 483.797 1697.86 489.161L1680.82 506.356C1677.98 503.201 1674.67 500.519 1670.57 498.468C1666.63 496.417 1661.58 495.313 1655.42 495.313C1649.75 495.313 1645.49 496.26 1642.65 498.31C1639.81 500.361 1638.39 503.043 1638.39 506.671C1638.39 510.615 1639.49 513.613 1641.7 515.348C1643.91 517.083 1647.54 518.345 1652.43 519.134L1664.1 521.027C1676.25 522.92 1685.24 526.706 1691.08 532.227C1696.91 537.749 1699.91 545.794 1699.91 555.89C1699.91 561.57 1698.96 566.775 1696.91 571.508C1694.86 576.241 1691.87 580.342 1688.08 583.655C1684.14 586.968 1679.4 589.65 1673.72 591.543C1667.89 593.593 1661.42 594.54 1654.16 594.54Z" fill="white" />
                  </g>
                </g>
                <g id="aundq">
                  <g id="a" class="wort">
                    <path id="Vector_25" d="M1141.15 737.78L1133.42 712.066H1097.45L1089.72 737.78H1065.9L1101.39 629.877H1130.58L1165.6 737.78H1141.15ZM1115.75 651.173H1114.96L1103.13 692.189H1127.9L1115.75 651.173Z" fill="white" />
                  </g>
                  <g id="und" class="wort">
                    <path id="Vector_26" d="M1239.43 629.877V695.975C1239.43 703.547 1240.85 709.227 1243.85 713.013C1246.84 716.799 1251.89 718.692 1258.83 718.692C1265.77 718.692 1270.82 716.799 1273.82 713.013C1276.82 709.227 1278.24 703.547 1278.24 695.975V629.877H1301.11V693.609C1301.11 701.497 1300.32 708.438 1298.9 714.275C1297.48 720.112 1294.96 724.844 1291.49 728.63C1288.02 732.416 1283.76 735.098 1278.39 736.991C1273.03 738.726 1266.56 739.673 1258.83 739.673C1251.1 739.673 1244.48 738.726 1239.27 736.991C1234.07 735.256 1229.49 732.416 1226.18 728.63C1222.71 724.844 1220.34 720.112 1218.76 714.275C1217.34 708.438 1216.55 701.497 1216.55 693.609V629.877H1239.43Z" fill="white" />
                    <path id="Vector_27" d="M1357.27 686.826L1346.86 665.213H1346.39V737.938H1324.3V630.034H1350.01L1379.51 680.989L1389.93 702.601H1390.4V629.877H1412.48V737.78H1386.77L1357.27 686.826Z" fill="white" />
                    <path id="Vector_28" d="M1436.3 629.877H1477.01C1484.1 629.877 1490.57 630.981 1496.41 633.347C1502.25 635.556 1507.29 639.026 1511.4 643.444C1515.5 647.861 1518.65 653.54 1521.02 660.323C1523.23 667.106 1524.49 674.994 1524.49 683.828C1524.49 692.82 1523.38 700.708 1521.02 707.334C1518.81 714.117 1515.5 719.638 1511.4 724.213C1507.29 728.63 1502.25 732.101 1496.41 734.309C1490.57 736.518 1484.1 737.78 1477.01 737.78H1436.3V629.877ZM1477.01 716.957C1483.95 716.957 1489.47 715.063 1493.57 711.12C1497.51 707.176 1499.56 700.866 1499.56 692.189V675.467C1499.56 666.791 1497.51 660.481 1493.57 656.537C1489.63 652.593 1484.1 650.7 1477.01 650.7H1459.81V716.799H1477.01V716.957Z" fill="white" />
                  </g>
                  <g id="q" class="wort">
                    <path id="Vector_29" d="M1622.45 683.671L1630.81 692.189C1632.08 688.719 1632.71 684.459 1632.71 679.096C1632.71 657.168 1620.88 651.962 1613.15 651.962C1605.42 651.962 1593.43 657.01 1593.43 679.096C1593.43 701.181 1605.26 706.229 1612.99 706.229C1614.25 706.229 1615.51 706.072 1616.93 705.756L1608.57 697.238L1622.45 683.671ZM1649.59 739.2L1636.34 725.475C1630.81 728.946 1622.14 730.681 1612.99 730.681C1583.02 730.681 1562.98 708.911 1562.98 678.78C1562.98 648.65 1583.17 627.037 1613.15 627.037C1643.12 627.037 1663.15 648.807 1663.15 678.938C1663.15 692.663 1658.89 704.494 1651.64 713.486L1663.31 725.475L1649.59 739.2Z" fill="white" />
                    <path id="Vector_30" d="M1698.18 715.221C1698.18 724.844 1690.29 732.732 1680.66 732.732C1671.04 732.732 1663.15 724.844 1663.15 715.221C1663.15 705.598 1671.04 697.711 1680.66 697.711C1690.45 697.711 1698.18 705.598 1698.18 715.221Z" fill="white" />
                    <path id="Vector_31" d="M1698.18 672.785C1698.18 682.408 1690.29 690.296 1680.66 690.296C1671.04 690.296 1663.15 682.408 1663.15 672.785C1663.15 663.162 1671.04 655.275 1680.66 655.275C1690.45 655.433 1698.18 663.162 1698.18 672.785Z" fill="white" />
                  </g>
                </g>
              </g>
              <path id="speech" d="M1351.28 125.539C1248.74 115.285 960.048 43.3499 968.882 288.025C977.874 532.543 1040.5 722.636 1044.92 871.397C1049.34 1020.16 1407.12 871.397 1471.96 871.397C1536.79 871.397 1552.41 975.356 1543.58 1001.07C1534.59 1026.78 1571.97 966.68 1594.22 895.849C1616.62 824.86 1704.49 862.247 1747.08 862.247C1789.51 862.247 1870.13 219.403 1782.89 180.438C1695.65 141.63 1442.93 134.689 1351.28 125.539Z" stroke="black" stroke-width="4.19449" />
            </g>
          </g>
        </svg>

      </div>

      <div className={`${HeroStyles.wrapper} fullorhalfpage herotext order-first order-md-last`}>
        <div className="row py-4 py-md-0">
          <div className="col-12 col-md-5">
            <h1>Hallo!</h1>
            <p class="standard">Du bist auf der Suche nach einem Ausbildungsplatz?<br/>Dann bewirb' dich bei uns für eine Ausbildung als Fachinformatiker:in für Anwendungsentwicklung oder Mediengestalter:in.</p>
            <p class="standard"><strong>Wofür interessierst du dich?</strong></p>
              <AnchorLink to="/ausbildung/#fachinformatiker" title="Fachinformatik" className="q-btn q-btn-secondary mr-3">Fachinformatik</AnchorLink>
              <AnchorLink to="/ausbildung/#mediengestalter" title="Mediengestaltung" className="mt-2 mt-md-0 q-btn q-btn-secondary">Mediengestaltung</AnchorLink>
          </div>
          <div className="col-7"></div>
        </div>
      </div>

    </div>

  )

}

export default Ausbildungheader