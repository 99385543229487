import React from "react"
import "../layout/layout.css"
import qlogo from "../../images/qlogo-circle-orange.svg"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import CTAKontaktmodul from "../ctaKontaktmodul"

export default function Kontakt({ ansprechpartner }) {

    const data = useStaticQuery(graphql`
        query bildQuery{
            juergen: file(relativePath: { eq: "images/kontakter/juergen-dworak.jpg" }) {
                childImageSharp {
                    gatsbyImageData(          
                    placeholder: BLURRED,
                    layout: FULL_WIDTH,
                    aspectRatio: 1
                    )
                }
            } 
            christian: file(relativePath: { eq: "images/kontakter/christian-frieling.jpg" }) {
                childImageSharp {
                    gatsbyImageData(          
                    placeholder: BLURRED,
                    layout: FULL_WIDTH,
                    aspectRatio: 1
                    )
                }
            } 
            hannspeter: file(relativePath: { eq: "images/kontakter/hanns-peter-windfeder.jpg" }) {
                childImageSharp {
                    gatsbyImageData(          
                    placeholder: BLURRED,
                    layout: FULL_WIDTH,
                    aspectRatio: 1
                    )
                }
            }                         
        }
    `)

    let pic;
    let contactData = () => {
        switch (ansprechpartner) {
            case "juergen-dworak":
                pic = getImage(data.juergen)
                return {
                    name: "Jürgen Dworak",
                    tel: "0208 3015-301",
                    mail: "juergen.dworak@Qmarketing.de"                    
                }
            case "hanns-peter-windfeder":
                pic = getImage(data.hannspeter)
                return {
                    name: "Hanns-Peter-Windfeder",
                    tel: "0208 3015-101",
                    mail: "hanns-peter.windfeder@Qmarketing.de"                    
                }
            case "christian-frieling":
                pic = getImage(data.christian)
                return {
                    name: "Christian Frieling",
                    tel: "0208 3015-124",
                    mail: "christian.frieling@Qmarketing.de"                    
                }
            default:
                return {
                    name: "Q:marketing",
                    strasse: "Düsseldorfer Straße 193",
                    ort: "45481 Mülheim an der Ruhr",
                    tel: "0208 3015-0",
                    mail: "dialog@Qmarketing.de",
                }
        }
    }
    let topContact = () => {
        var output
        if (contact.strasse == null && contact.ort == null) {
            output = <div className="font-weight-bold">{contact.name}</div>
        } else {
            output = (
                <>
                    {contact.name}
                    <br />
                    {contact.strasse}
                    <br />
                    {contact.ort}
                    <br />
                </>
            )
        }
        return output
    }

    var contact = contactData()

    return (
        <div className="container-fluid pb-5">
            <div className={`row greyback contentmodul`}>
                <div className="col-sm-12 col-md-4 order-1">
                    <h2>Kontakt</h2>
                </div>
                <div className="col-sm-6 col-md-4 d-flex flex-column justify-content-center align-items-start order-3 order-md-2 mt-4 mt-md-0">
                    <h3>{topContact()}</h3>
                    <p>
                        Telefon {contact.tel} <br />
                        {contact.mail}                        
                        <br />
                    </p>
                    <div className="row">
                    <div className="col-sm-6 col-md-4">
                        <a href={"mailto:" + contact.mail} className="q-btn q-btn-primary d-inline-flex">
                            E-Mail
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                        <CTAKontaktmodul />
                    </div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-4 order-2 order-md-3">
                    <div className="qlogoWrap">
                        <img src={qlogo} width="40px" />
                    </div>
                    <GatsbyImage image={pic} alt={contact.name} className="shadow1" />
                </div>
            </div>
        </div>
    )
}
